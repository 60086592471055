/*
Meat: Beef, Pork, Chicken, Turkey, Duck, Salmon, Fish, Vegetarian
Side: Rice, Potato, Pasta, Salad
Others: Healthy, Quick
*/


const myArr = [
    {id: 1, name: "The Most Amazing Lasagna", tags: ['beef', 'pasta'], urlcode: "zVqunZUuwSs"}, 
    {id: 2, name: "The BEST Beef Stew Recipe - Hundreds of 5-Star Reviews!!", tags: ['beef', 'potato'], urlcode: "8p-f9DcVkgE"}, 
    {id: 3, name: "How To Make Butter Chicken At Home | Restaurant Style Recipe | The Bombay Chef – Varun Inamdar", tags: ["chicken"], urlcode: "a03U45jFxOI"}, 
    {id: 4, name: "ONE PAN SALMON AND VEGETABLE BAKE | Ready in 30 Min | Baked Salmon & Veggies", tags: ["salmon", "seafood", "potato", "easy"], urlcode: "FQQpIUaSqY0"}, 
    {id: 5, name: "How To Make Creamy Shrimp Alfredo Pasta - 30 Minute Meal", tags: ["seafood", "pasta"], urlcode: "5vy9HeL8mOc"}, 
    {id: 6, name: "Spicy Smoky 'Meaty' Vegan Chili | 1-Pot Meal! | The Wicked Kitchen", tags: ["vegetarian"], urlcode: "jSXA2LYQud4"}, 
    {id: 7, name: "Assam Crispy Duck | Jamie Oliver", tags: ["chicken", "rice"], urlcode: "l076AppiLmM"}, 
    {id: 8, name: "Gordon Ramsay Makes an All American Burger", tags: ['beef'], urlcode: "ulhRORJpuBM"}, 
    {id: 9, name: "My New All Time Favourite Burger... Xi'an-style Spicy Beef 'Burgers' - Marion's Kitchen", tags: ['beef'], urlcode: "oMjqgRDY0RY"}, 
    {id: 10, name: "Chicken Biryani - A step-by-step guide to the best rice dish ever", tags: ["chicken", "rice"], urlcode: "6XlMguO9r-M"}, 
    {id: 11, name: "How to make Bolognese | Gennaro Contaldo | Italian Special", tags: ["beef", "pork", "pasta"], urlcode: "_qy6HNqxhKU"}, 
    {id: 12, name: "How to Make SPAGHETTI alla CARBONARA in 2021 (Next Level Carbonara)", tags: ["pork", "pasta"], urlcode: "6Oy5ITdDQ3o"}, 
    {id: 13, name: "2 Dollar Spaghetti and Meatballs | But Cheaper", tags: ["beef", "pork","pasta"], urlcode: "izAm3Ie9mNo"}, 
    {id: 14, name: "Creamy Tomato Salmon Spaghetti | Ready in 20 mins!", tags: ["salmon", "seafood", "pasta"], urlcode: "9a4rbR3OyOs"}, 
    {id: 15, name: "Easy Creamy Tuna Pasta Bake = Super Comfort Food!", tags: ["seafood", "pasta"], urlcode: "3oBDSwpx_UA"}, 
    {id: 16, name: "BEST CHICKEN SALAD RECIPE | easy & healthy", tags: ["chicken", "healthy", "salad"], urlcode: "cSAGdGSdE6k"}, 
    {id: 17, name: "Gordon Ramsay's Spicy Fried Rice Recipe from Indonesia", tags: ["rice"], urlcode: "NybhwoK6am0"}, 
    {id: 18, name: "BETTER THAN TAKEOUT AND EASY! Chinese Chicken Fried Rice Recipe", tags: ["chicken", "rice"], urlcode: "7AxydboW8v8"}, 
    {id: 19, name: "Extra Crispy Baked Chicken Drumsticks", tags: ["chicken"], urlcode: "M48SEReysVI"}, 
    {id: 20, name: "Skillet Garlic Butter Herb Steak and Potatoes Recipe - Easy Steak and Potatoes", tags: ["beef", "potato"], urlcode: "lkwJhbeIQaE"}, 
    {id: 21, name: "Easy Authentic Thai Green Curry At Home", tags: ["chicken", "rice"], urlcode: "rTK8MWYYLko"}, 
    {id: 22, name: "Easy Amazing Shakshuka Recipe", tags: ["vegetarian"], urlcode: "m5Kn9WmOCrw"}, 
    {id: 23, name: "How to make... One Pan Pasta!", tags: ["pasta", "vegetarian", "easy"], urlcode: "0VQEJARHJ44"}, 
    {id: 24, name: "One Pot Creamy Garlic Mushroom Chicken Pasta", tags: ["pasta", "chicken", "easy"], urlcode: "85eG2gsJ0Ko"}, 
    {id: 25, name: "Texas Chili Recipe (Won over 30 Cookoffs!)", tags: ["beef", "pork"], urlcode: "fSbzShDHsDQ"}, 
    {id: 26, name: "Beef & Broccoli Made Better And Healthier Than In Restaurants", tags: ["beef", "healthy"], urlcode: "nWqPFOv5dpQ"}, 
    {id: 27, name: "EASY CHICKEN FAJITAS (AND HOMEMADE TORTILLAS!) | SAM THE COOKING GUY", tags: ["chicken", "healthy", "easy"], urlcode: "Ts_qteZlLdM"}, 
    {id: 28, name: "Classic Shepherd's Pie | Gordon Ramsay", tags: ["potato"], urlcode: "M_GNznvIN1E"}, 
    {id: 29, name: "My all-time favourite spicy Chinese noodle soup recipe | Shanghai Hot Sauce Noodles", tags: ["pasta", "pork"], urlcode: "2aBB0Sp9FsM"}, 
    {id: 30, name: "Garlic Mushrooms Better than ANY Restaurant! | Chef Jean-Pierre", tags: ["vegetarian"], urlcode: "isDwqSwg9Eg"}, 
    {id: 31, name: "How to Make Clam Chowder | Chef Jean-Pierre", tags: ["seafood"], urlcode: "sdMyin7g8wY"}, 
    {id: 32, name: "One Pan Honey Garlic Pork Chops Recipe", tags: ["pork"], urlcode: "_oHV9wqSdUY"}, 
    {id: 33, name: "Just Amazing Meatloaf | Chef Jean-Pierre", tags: ["beef", "pork"], urlcode: "U3DQRlF_qnM"}, 
    {id: 34, name: "The Easiest Sausage and Peppers Recipe | Chef Jean-Pierre", tags: ["pork"], urlcode: "NquOqszxzZ0"}, 
    {id: 35, name: "Chicken & Sausage Jambalaya | Chef Jean-Pierre", tags: ["chicken", "pork", "rice"], urlcode: "OrLV7EMczb4"}, 
    {id: 36, name: "Cordon Bleu Pork Chops | Chef Jean-Pierre", tags: ["pork"], urlcode: "MsdS0jEhtM8"}, 
    {id: 37, name: "Chicken Dinner On a Budget | Chef Jean-Pierre", tags: ["chicken"], urlcode: "meLkUSnwr_8"}, 
    {id: 38, name: "Elegant Chicken Cacciatore | Chef Jean-Pierre", tags: ["chicken"], urlcode: "7_cwzzVnEXg"}, 
    {id: 39, name: "Quick and Easy Chicken Stir Fry | Chef Jean-Pierre", tags: ["chicken", "rice", "easy"], urlcode: "JHkcnXMnsLw"}, 
    {id: 40, name: "A Seafood Lover's Dream (Cioppino) | Chef Jean-Pierre", tags: ["salmon", "seafood"], urlcode: "2Yf5YngiC1k"}, 
    {id: 41, name: "Delicious and Beautiful Poke Bowl | Chef Jean-Pierre", tags: ["seafood", "rice", "healthy"], urlcode: "y4kvTieDiY4"}, 
    {id: 42, name: "The 2 Dollar Burrito | But Cheaper", tags: ["rice", "chicken", "pork", ], urlcode: "1Dd7GNm68hI"}, 
    {id: 43, name: "Delicious Thai Fish Cakes!", tags: ["seafood"], urlcode: "OGKqmRqFxCM"}, 
    {id: 44, name: "High Protein Quesadillas (3 Ways)", tags: ["beef", "chicken", ], urlcode: "BtKn264dsd8"}, 
    {id: 45, name: "How To Make The Perfect Quesadilla", tags: ["beef", "vegetarian"], urlcode: "mUA5m-113HQ"}, 
    {id: 46, name: "The $2 Gyro | But Cheaper", tags: ["pork", "potato"], urlcode: "Asu5cw4USng"}, 
    {id: 47, name: "The 1 Dollar Healthy Rice Bowl", tags: ["rice", "chicken", "healthy"], urlcode: "oxXNklNgmbM"}, 
    {id: 48, name: "The $3 Healthy Burrito Bowl", tags: ["rice", "chicken", "healthy"], urlcode: "MTMQJwE4l0M"}, 
    {id: 49, name: "Perfect Homemade Barbacoa Tacos (2 Ways)", tags: ["beef"], urlcode: "ct4_vsJizG4"}, 
    {id: 50, name: "Pan-Fried Salmon with Tomato Couscous | Jamie Oliver", tags: ["salmon", "seafood", "rice"], urlcode: "rgNieLXfFco"}, 
    {id: 51, name: "Couscous Salad Recipe (High Protein & Healthy)", tags: ["rice", "salad", "healthy"], urlcode: "fk74pQGn89M"}, 
    {id: 52, name: "Quick Teriyaki Salmon | WEEKNIGHTING", tags: ["salmon", "seafood", "rice", ], urlcode: "hsoMyZt-ebk"}, 
    {id: 53, name: "Quick Korean Beef Rice Bowl | WEEKNIGHTING", tags: ["beef", "rice", "healthy"], urlcode: "olK8IrYgS0c"}, 
    {id: 54, name: "Oklahoma Onion Burgers are SHOCKINGLY Good", tags: ["beef"], urlcode: "NZWtGNIzUQQ"}, 
    {id: 55, name: "Perfect Pan Seared Salmon with Lemon butter Cream Sauce and Crispy Skin", tags: ["salmon", "seafood", "rice"], urlcode: "K4TWAvZPURg"}, 
    {id: 56, name: "Easy Avocado Salmon Salad | Paleo or Keto Recipe", tags: ["salmon", "seafood", "salad", "healthy"], urlcode: "vbV1h68Mnms"}, 
    {id: 57, name: "The salmon salad recipe that will have you craving more salad! | Vietnamese Salmon Salad", tags: ["salmon", "seafood", "salad", "healthy"], urlcode: "QRlNK2mMdHg"}, 
    {id: 58, name: "Best Mediterranean Pearl Couscous Salad | The Mediterranean Dish", tags: ["salad", "healthy", "rice", "vegetarian"], urlcode: "BWxJfJy49r0"}, 
    {id: 59, name: "Quick & Easy Moroccan Couscous (Vegan)", tags: ["salad", "healthy", "rice", "vegetarian", "easy"], urlcode: "MrhadmHccDw"}, 
    {id: 60, name: "The most AMAZING 20 MINUTE Seafood Paella", tags: ["seafood", "rice"], urlcode: "NnuL_jPHmm8"}, 
    {id: 61, name: "Classic Spanish Seafood Stew | A Timeless Recipe from Barcelona Spain", tags: ["seafood"], urlcode: "FFAWo5VS56c"}, 
    {id: 62, name: "Unforgettable Recipe. SEAFOOD PASTA MARINARA", tags: ["seafood", "pasta"], urlcode: "2W7-GtBh4sE"}, 
    {id: 63, name: "Prawn Linguine | Jamie’s 15 Minute Meals (2012)", tags: ["seafood", "pasta", "easy"], urlcode: "o6ZsrznRGBU"}, 
    {id: 64, name: "Chickpea Curry, an (almost) perfect one-pot meal", tags: ["rice", "vegetarian", "easy", "healthy"], urlcode: "5bsvxIDkm4g"}, 
    {id: 65, name: "$2 Chicken Tikka Masala | But Cheaper", tags: ["chicken", "rice",], urlcode: "O5l1SMavklI"}, 
    {id: 66, name: "The BEST Chicken Tikka Masala", tags: ["chicken", "rice"], urlcode: "HMfUsS9zeuw"}, 
    {id: 67, name: "North Indian Chicken Korma", tags: ["chicken", "rice"], urlcode: "935VEghEnio"}, 
    {id: 68, name: "Classic Homemade Shepherd’s Pie Recipe", tags: ["potato"], urlcode: "6HjlOilzJWQ"}, 
    {id: 69, name: "Gordon Ramsay's Ultimate Pulled Pork", tags: ["pork" ], urlcode: "j-gBbNgfDMI"}, 
    {id: 70, name: "Cider-Braised Pork Cheeks - How to Cook Pork Cheeks", tags: ["pork", "potato"], urlcode: "PvIRrw_-z5o"}, 
    {id: 71, name: "Beef & Ale Stew with Mustard Dumplings | Gordon Ramsay", tags: ["beef", ], urlcode: "OhLGGkWg0ts"}, 
    {id: 72, name: "Aloo Gobi Recipe | You Need This Recipe", tags: ["potato", "vegetarian"], urlcode: "ISLcOisod1k"}, 
    {id: 73, name: "The Chicken Curry I eat every week", tags: ["chicken", "rice"], urlcode: "7wsa7XsaHQo"}, 
    {id: 74, name: "Thai Panang Chicken Curry | Thai Panang Curry Recipe with Chicken | Panang Curry with Chicken", tags: ["chicken", "rice"], urlcode: "dYZHzK7YfYI"}, 
    {id: 75, name: "Vegetarian Lasagna", tags: ["vegetarian", "pasta"], urlcode: "nVY6Ze5VYsk"}, 
    {id: 76, name: "Gordon Ramsay's Ultimate Caesar Salad", tags: ["salad", "chicken"], urlcode: "IGlWE4AFQ5Q"}, 
    {id: 77, name: "The Best Salad You'll Ever Make (Restaurant-Quality) | Epicurious 101", tags: ["salad", "vegetarian", "healthy"], urlcode: "QmFT-L45i2w"}, 
    {id: 78, name: "Easy Greek Salad Recipe", tags: ["salad", "vegetarian"], urlcode: "kwq4vl610iY"}, 
    {id: 79, name: "Potato Salad", tags: ["potato", "salad", "vegetarian"], urlcode: "dB5mId3g5i4"}, 
    {id: 80, name: "How to Make a Perfect Ratatouille", tags: ["vegetarian"], urlcode: "RQlp-p_Qcsw"}, 
    {id: 81, name: "Chicken Fricassee - quick French Chicken Stew", tags: ["chicken", "potato"], urlcode: "sqelF9LaAlQ"}, 
    {id: 82, name: "Steak Dinner For Two", tags: ["beef"], urlcode: "7wXtuIqCT2c"}, 
    {id: 83, name: "Chicken Francaise Recipe over 200 Million Views", tags: ["chicken"], urlcode: "Lw7EixBGu60"}, 
    {id: 84, name: "THE ONE DINNER I ALWAYS MAKE TO IMPRESS MY GUESTS | SAM THE COOKING GUY", tags: ["beef", "potato"], urlcode: "Sqog5ILMAV0"}, 
    {id: 85, name: "Dublin Coddle - Irish Sausage and Potato Stew - Food Wishes", tags: ["pork", "potato"], urlcode: "51Wcy67CP00"}, 
    {id: 86, name: "Tuscan Chicken | The Ultimate Chicken Dinner?", tags: ["chicken", "pasta", "potato"], urlcode: "SPum_xK5618"}, 
    {id: 87, name: "The Ultimate Steak Sandwich | Gordon Ramsay", tags: ["beef"], urlcode: "jwu2y9x5OlM"}, 
    {id: 88, name: "Try this Restaurant Saffron and Shrimp Risotto! Your Taste Buds will Reward You!", tags: ["seafood", "rice"], urlcode: "_TCU-3z1kyA"}, 
    {id: 89, name: "Seafood Risotto | Akis Petretzikis", tags: ["seafood", "rice"], urlcode: "0-5W1Nks72Y"}, 
    {id: 90, name: " Seafood Baked Potato ", tags: ["seafood", "potato", "pork"], urlcode: "mUc4V-L7Hxk"}, 
    {id: 91, name: "Tomahawk Ribeye w/ Compound Butter and Seafood Baked Potato | Father's Day Dinner #MrMakeItHappen", tags: ["beef", "pork", "potato", "seafood"], urlcode: "qhcjHghXALA"}, 
    {id: 92, name: "This is How You Make a Twice-Baked Potato | Chef Jean-Pierre", tags: ["potato", " vegetarian"], urlcode: "4AO5xsIqpPM"}, 
    {id: 93, name: "Hungarian Beef Goulash Recipe", tags: ["beef", "potato"], urlcode: "VT2VBV74VTI"}, 
    {id: 94, name: "Bobby Flay's Slow-Roasted Salmon and Potatoes | Food Network", tags: ["salmon", "seafood",  "potato"], urlcode: "H453GqQshFY"}, 
    {id: 95, name: "DELICIOUS Baked Honey Garlic Salmon! | EASY Salmon In The Oven Recipe", tags: ["salmon", "seafood"], urlcode: "DpP-f-O6ADU"}, 
    {id: 96, name: "DELICIOUS Rice with Salmon & Vegetables | Quick & Healthy Recipe", tags: ["salmon", "seafood", "rice", "healthy"], urlcode: "QIG9JAG8E28"}, 
    {id: 97, name: "Spanish Spinach and Eggs | A CLASSIC Recipe from Sevilla Spain", tags: ["vegetarian", "healthy"], urlcode: "lpdUZ7LdmfY"}, 
    {id: 98, name: "Gordon Ramsay Makes Asian Inspired Street Food Noodles", tags: ["pasta", "pork"], urlcode: "xhsN_36OW4w"}, 
    {id: 99, name: "Mexican Inspired Rice and Beans Recipe Healthy One Pot Black Bean Vegan Food (Super Easy)", tags: ["vegetarian", "healthy", "rice"], urlcode: "UNMrwudowfg"}, 
    {id: 100, name: "The Red Lentil Curry Recipe I've been making EVERY WEEK!", tags: ["vegetarian", "rice", "healthy"], urlcode: "vDS9JZfEnuY"}, 
    
    
]


for (let i = myArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = myArr[i];
    myArr[i] = myArr[j];
    myArr[j] = temp;
}


const videoList = () => { 


      
    
    return (
      myArr





)
}

export default videoList