import { useState, useEffect } from 'react'
import Searchitem from './Searchitem'
import { Link } from 'react-router-dom'


const Searchbar = ({ onSearch }) => {

    const [beef, setBeef] = useState(false)
    const [chicken, setChicken] = useState(false)
    const [pork, setPork] = useState(false)
    const [salmon, setSalmon] = useState(false)
    const [seafood, setSeafood] = useState(false)
    const [pasta, setPasta] = useState(false)
    const [potato, setPotato] = useState(false)
    const [rice, setRice] = useState(false)
    const [salad, setSalad] = useState(false)
    const [healthy, setHealthy] = useState(false)
    const [vegetarian, setVegetarian] = useState(false)
    const [easy, setEasy] = useState(false)

    const findValues = () => {
        const searchValues = []
        if (beef) {searchValues.push('beef')}
        if (chicken) {searchValues.push('chicken')}
        if (pork) {searchValues.push('pork')}
        if (salmon) {searchValues.push('salmon')}
        if (seafood) {searchValues.push('seafood')}
        if (pasta) {searchValues.push('pasta')}
        if (potato) {searchValues.push('potato')}
        if (rice) {searchValues.push('rice')}
        if (salad) {searchValues.push('salad')}
        if (healthy) {searchValues.push('healthy')}
        if (vegetarian) {searchValues.push('vegetarian')}
        if (easy) {searchValues.push('easy')}
        onSearch(searchValues, false)
    }
    const findValuesRandom = () => {
        const searchValues = []
        if (beef) {searchValues.push('beef')}
        if (chicken) {searchValues.push('chicken')}
        if (pork) {searchValues.push('pork')}
        if (salmon) {searchValues.push('salmon')}
        if (seafood) {searchValues.push('seafood')}
        if (pasta) {searchValues.push('pasta')}
        if (potato) {searchValues.push('potato')}
        if (rice) {searchValues.push('rice')}
        if (salad) {searchValues.push('salad')}
        if (healthy) {searchValues.push('healthy')}
        if (vegetarian) {searchValues.push('vegetarian')}
        if (easy) {searchValues.push('easy')}
        onSearch(searchValues, true)
    }

    useEffect(() => {
        findValues()
    }, [beef, chicken, pork, salmon, seafood, pasta, potato, rice, salad, healthy, vegetarian, easy])


    return (
        <div>
            <div className='option-container'>
            <Searchitem name='Beef' value={beef} setValue={setBeef}/>   
            <Searchitem name='Chicken' value={chicken} setValue={setChicken}/> 
            <Searchitem name='Pork' value={pork} setValue={setPork}/> 
            <Searchitem name='Salmon' value={salmon} setValue={setSalmon}/> 
            <Searchitem name='Seafood' value={seafood} setValue={setSeafood}/> 
            <Searchitem name='Pasta' value={pasta} setValue={setPasta}/> 
            <Searchitem name='Potato' value={potato} setValue={setPotato}/> 
            <Searchitem name='Rice' value={rice} setValue={setRice}/> 
            <Searchitem name='Salad' value={salad} setValue={setSalad}/> 
            <Searchitem name='Healthy' value={healthy} setValue={setHealthy}/> 
            <Searchitem name='Vegetarian' value={vegetarian} setValue={setVegetarian}/>    
            <Searchitem name='Easy' value={easy} setValue={setEasy}/> 
            <div className='option'>
            <img className="searchimage" src="/icons/Question.png" onClick={() => findValuesRandom()} />
            <span className="searchtext">Random</span></div>
                
            </div>  
        </div>


    )
}

export default Searchbar