import { Link } from 'react-router-dom'

const Searchitem = ({ value, setValue, name }) => {



    return (
        <div className='option' onClick={() => setValue(!value)}>
            <Link to="/"><img className={value ? "searchimage-active" : "searchimage"} src={`/icons/${name}.png`} alt={name}/></Link>
            <span className="searchtext">{name}</span>


        </div>



    )
}

export default Searchitem