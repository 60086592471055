

const Footer = (play) => {

    return (
        <div className='footer'>
            <p className='footer-text'>
                <strong>What to eat for dinner? </strong> <br/><br/>
                Figuring out what to eat for dinner can be a challenging task, and it's 
                something that most of us struggle with from time to time. Whether you're 
                trying to plan meals for the week or just deciding what to eat on a particular night, 
                there are a lot of factors to consider, and it can be tough to know where to start.
                <br/><br/>
                One of the biggest challenges in figuring out what to eat for dinner is simply the sheer 
                number of options available to us. With so many different cuisines, ingredients, and cooking 
                styles to choose from, it can be overwhelming to try and narrow down your choices. That's why
                we have created this tool, which can guide you to a video recipe, based on simple preferences. 
                If the choice is still overwhelming, we urge you to try the random button, which will take you 
                directly to a recipe video, based on your preferences. Choosing what to eat for dinner doesn't 
                get any easier than this!
                <br/><br/>
                Another factor that makes it hard to decide what to eat for dinner is the pressure to make healthy 
                choices. Many of us are trying to eat more whole foods, cut down on sugar and processed foods, 
                and ensure that we're getting enough nutrients in our diets. That is why we have implemented the 
                healthy choice, so you can get ideas for healthy dinners in an instant! We have also added the easy 
                choice, if you're looking for simple dishes witch takes a low effort to cook. 
                <br/><br/>
                Ultimately, the key to figuring out what to eat for dinner is to approach it with a sense of curiosity 
                and experimentation. Don't be afraid to try new things, but also don't put too much pressure on 
                yourself to make the perfect meal every time. 
                <br/><br/><br/><br/><br/><br/><br/><br/>
                © Decide My Dinner 2023


         </p>
         <a className="attributelink" href="https://www.flaticon.com/authors/freepik">Search icons created by Freepik - Flaticon</a>
        </div>
    )


}

export default Footer