import Video from "./Video"


const Results = ({ list, small }) => {

    

  


    return (
        <div className="search-container">
        {list.map((video) => (
            <Video name={video['name']} urlcode={video['urlcode']} id={video['id']} key={video['id']} small={small}/>
        ))}
        </div>
    )

        
}     


    



export default Results
