
import { Link } from 'react-router-dom'



const Video = ({name, urlcode, id, small}) => {




    return (
        <div className={small ? 'container-small' : 'container'}><Link to={`/play/${id}/${name}`}>
        <img  className={small ? 'thumbnail-small' :'thumbnail'}
            src={'https://i3.ytimg.com/vi/' + urlcode + '/maxresdefault.jpg'} 
            alt='thumbnail'
            />
        <p className={small ? 'thumbnail-title-small' :'thumbnail-title'}>{name}</p>
        </Link>
    </div>
    )
}


export default Video