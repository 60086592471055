import { useParams } from "react-router-dom";
import videoList from "../list";


const Play = ({small}) => {

    const { id } = useParams()
    const video = videoList().filter(vid => vid['id'] === Number(id))
    const url = video[0]['urlcode']
    const name = video[0]['name']
    
    


    return(
        <div className='player'>
            <iframe 
            width={small ? '403' : "800"}
            height={small ? '227' : "450"}
            src={`https://www.youtube.com/embed/${url}?autoplay=1`}
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            <span className="playertitle">{name}</span>
        </div>
    )
}

export default Play;