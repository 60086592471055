import './App.css';
import { useState, useEffect } from 'react';
import videoList from './list'
import Searchbar from './Components/Searchbar';
import Results from './Components/Results';
import { Routes, Route, useNavigate } from 'react-router-dom' 
import Play from './Components/Play';
import Footer from './Components/Footer';






function App() {

  
  const [videos, setVideos] = useState(['none'])
  const [small, setSmall] = useState(false)

  const size = () => {
    console.log(window.innerWidth)
    if (window.innerWidth < 800) {
        setSmall(true)
    } else {
        setSmall(false)
    }
};


window.addEventListener('resize', size);

  
  const navigate = useNavigate()

  const search = (values, random) => {
    const getVideos = videoList()
    
  

    const videosToSearch = []
    for (let i = 0; i < getVideos.length; i++) {
      if (values.every(value => getVideos[i]['tags'].includes(value))) {
        videosToSearch.push(getVideos[i])
      }

    }
    if (random) {
      
      const randnum = Math.floor(Math.random() * videosToSearch.length)
      const randomVideo = []
      randomVideo.push(videosToSearch[randnum])
      navigate(`/play/${videosToSearch[randnum]['id']}/${videosToSearch[randnum]['name']}`)
     
    } else {
    setVideos(videosToSearch)
    
    
  }
  }

  useEffect(() => {
    search([])
    size()
   }, [])

 return (
  <>
  <div className='heading'>
  <h1>Decide My Dinner</h1>
  <Searchbar onSearch={search}/>
  
  </div>
  <Routes>
    <Route path ="/" element={(videos.length > 0 ? <Results list={videos} small={small}/> : <h3>No videos to show</h3>)} />  
    <Route path="/play/:id/:name" element={<Play  small={small}/>} />
  </Routes>
  <Footer />
  </>
 )

}

export default App;
